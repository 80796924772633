import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

var base64 = require("base-64");

const ProductsContext = React.createContext([]);

function ProductsProvider(props) {
  const { children } = props;
  const [products, setProducts] = useState({
    titre: "",
    id_bigwax: "",
    id_b64: "",
    categories: "",
    handle: "",
    prix: "",
    instock: "",
    images: [""],
  });

  const graphql_products = useStaticQuery(graphql`
    {
      allShopifyProduct {
        edges {
          node {
            variants {
              price
            }
            availableForSale
            title
            shopifyId
            images {
              originalSrc
            }
          }
        }
      }
      allContentfulPageShop {
        edges {
          node {
            contentful_id
            listeProduits {
              bigwaxId
              categories
              titre
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    let produits_contentful_array =
      graphql_products.allContentfulPageShop.edges[0].node.listeProduits;
    const results = produits_contentful_array.map((contentful_product) => {
      let id_b64 = base64.encode(
        "gid://shopify/Product/" + contentful_product.bigwaxId
      );
      let current_shopify_product = graphql_products.allShopifyProduct.edges.filter(
        (shopify_product) => {
          return shopify_product.node.shopifyId === id_b64;
        }
      );

      return {
        titre: contentful_product.titre,
        id_bigwax: contentful_product.bigwaxId,
        id_b64: id_b64,
        categories: contentful_product.categories,
        prix: current_shopify_product[0].node.variants[0].price,
        instock: current_shopify_product[0].node.availableForSale,
        images: current_shopify_product[0].node.images.map(
          (image) => image.originalSrc
        ),
      };
    });

    setProducts(results);
  }, [graphql_products]);

  return (
    <ProductsContext.Provider value={products}>
      {children}
    </ProductsContext.Provider>
  );
}
export default ProductsContext;
export { ProductsProvider };
