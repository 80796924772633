import "./src/styles/global.scss";

import { LocaleProvider } from "./src/context/LocaleContext";
import { ProductsProvider } from "./src/context/ProductsContext";
import React from "react";
import { ShopClickedProvider } from "./src/context/ShopClickedContext";

export const wrapRootElement = ({ element }) => {
  return (
    <ProductsProvider>
      <LocaleProvider>
        <ShopClickedProvider>{element}</ShopClickedProvider>
      </LocaleProvider>
    </ProductsProvider>
  );
};
