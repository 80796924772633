import React, { useState } from "react";

const LocaleContext = React.createContext([]);

function LocaleProvider(props) {
  const { children } = props;
  const [locale, setLocale] = useState("fr");

  return (
    <LocaleContext.Provider value={[locale, setLocale]}>
      {children}
    </LocaleContext.Provider>
  );
}
export default LocaleContext;
export { LocaleProvider };
