import React, { useState } from "react";

const ShopClickedContext = React.createContext([]);

function ShopClickedProvider(props) {
  const { children } = props;
  const [shopClicked, setShopClicked] = useState(false);

  return (
    <ShopClickedContext.Provider value={[shopClicked, setShopClicked]}>
      {children}
    </ShopClickedContext.Provider>
  );
}
export default ShopClickedContext;
export { ShopClickedProvider };
